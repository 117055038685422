import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import QTSI from "./QTSI";
import Logs from "./Logs";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={QTSI} />
        <Route exact path="/logs" component={Logs} />
        <Route path="/" render={() => <div>404</div>} />
      </Switch>
    </Router>
  );
}

export default App;
