class FriedmanApi {
  post = async (url, body = {}, params = {}) => {
    console.log("body", body);
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods":
          "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
      },
      body: JSON.stringify(body),
      ...params,
    });
    return response.json();
  };
}

export default new FriedmanApi();
