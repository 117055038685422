import React, { useReducer, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Checkbox,
  MenuItem,
  Select,
  FormControl,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import ClearIcon from "@material-ui/icons/Clear";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import FriedmanApi from "api/FriedmanApi";
import FirebaseApi from "api/FirebaseApi";
import withTries from "utils/withTries";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "60%",
    margin: "0 auto",
    marginTop: "1%",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  ErrorContainer: {
    display: "flex",
    justifyContent: "center",
    color: "red",
    fontWeight: "bold",
    marginTop: "1%",
  },
}));

const header = [
  {
    name: "sales-order",
    label: "Order Number",
  },
  {
    name: "priority",
    label: "Priority",
  },
  {
    name: "ack",
    label: "Acknowledgement",
  },
  {
    name: "req-date",
    label: "Request Date",
  },
  {
    name: "status",
    label: "Status",
  },
  {
    name: "clearRow",
    label: "Clear Row",
  },
  {
    name: "deleteRow",
    label: "Delete Row",
  },
];

const defaultRow = {
  "sales-order": "",
  suffix: 0,
  priority: false,
  "order-type": "SI",
  "req-date": "",
  "del-date": "",
  "sched-lock": "",
  "sync-to-date": "",
  ack: "E",
  status: "",
};

const reducer = (rows, { type, value }) => {
  switch (type) {
    case "add":
      return [...rows, value];
    case "remove":
      return rows.filter((_, i) => i !== value.rowIndex);
    case "edit":
      return rows.map((row, i) => (i === value.rowIndex ? value.row : row));
    case "clear":
      return rows.map((row, i) => (i === value.rowIndex ? defaultRow : row));
    case "setRows":
      return value;
    default:
      return [...rows];
  }
};

const initialState = [defaultRow];

export default function QTSI() {
  const classes = useStyles();
  const [rows, dispatch] = useReducer(reducer, initialState);
  const [apiError, setApiError] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState(null);

  const prepareData = () => {
    setApiError(false);
    setApiErrorMessage(null);
    dispatch({
      type: "setRows",
      value: rows.map((row) => {
        row.status = "Pending";
        row.error = false;
        return row;
      }),
    });

    const payload = rows
      .filter((row) => row["sales-order"] !== "")
      .map((row) => {
        let obj = {
          ...row,
          "sales-order": parseInt(row["sales-order"]),
          priority: row.priority ? "HOT" : "",
          "sched-lock": row["req-date"] ? "LOCK" : row["sched-lock"],
          "req-date": row["req-date"]
            ? moment(row["req-date"]).format("YYYY-MM-DD")
            : "",
          "del-date": row["req-date"]
            ? moment(row["req-date"]).format("YYYY-MM-DD")
            : "",
        };
        delete obj.status;
        delete obj.error;
        if (obj.ack === "N/A") delete obj.ack;
        return obj;
      });
    postSalesOrders(payload);
  };

  const postSalesOrders = async (payload) => {
    let errors = {};
    console.log("payload", payload);
    const { response, error } = await withTries(() =>
      FriedmanApi.post(process.env.REACT_APP_API_URL, {
        "convert-orders": payload,
      })
    );
    console.log("response", response);
    console.log("error", error);
    if (error) {
      setApiError(true);
      setApiErrorMessage(error.message);
      return;
    }
    if (response.status === "error") {
      errors = response.failed_quotes.reduce((acc, salesOrder, idx) => {
        acc[salesOrder] = response.failed_quotes_reason[idx];
        return acc;
      }, {});
    }

    const newRows = rows.map((item) => {
      const salesOrder = item["sales-order"];
      if (Reflect.has(errors, salesOrder)) {
        item.status = errors[salesOrder];
        item.error = true;
      } else {
        item.status = "success";
        item.error = false;
      }
      return item;
    });

    dispatch({ type: "setRows", value: newRows });
    FirebaseApi.addDocToFirebase(
      rows.filter((obj) => obj["sales-order"] !== "")
    );
  };

  return (
    <>
      <div className={classes.ErrorContainer}>
        {apiError ? (
          <Typography variant="h3" gutterBottom>
            {apiErrorMessage}
          </Typography>
        ) : null}
      </div>
      <TableContainer
        className={classes.container}
        elevation={8}
        component={Paper}
      >
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow className={classes.tableRow}>
              {header.map((item, i) => (
                <TableCell key={i} align="center">
                  {item.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
              <TableRow key={i} scope="row">
                <TableCell align="center">
                  <TextField
                    autoFocus
                    label="Order Number"
                    onKeyPress={(e) => {
                      if (e.key === "Enter" && row["sales-order"] !== "") {
                        dispatch({ type: "add", value: defaultRow });
                      }
                    }}
                    value={row["sales-order"]}
                    onChange={(e) =>
                      dispatch({
                        type: "edit",
                        value: {
                          rowIndex: i,
                          row: { ...row, "sales-order": e.target.value },
                        },
                      })
                    }
                    variant="outlined"
                  />
                </TableCell>
                <TableCell align="center">
                  <Checkbox
                    checked={row.priority}
                    onChange={() =>
                      dispatch({
                        type: "edit",
                        value: {
                          rowIndex: i,
                          row: { ...row, priority: !row.priority },
                        },
                      })
                    }
                    value={row.priority}
                  />
                </TableCell>
                <TableCell align="center">
                  <FormControl className={classes.formControl}>
                    <Select
                      value={row.ack}
                      onChange={(e) =>
                        dispatch({
                          type: "edit",
                          value: {
                            rowIndex: i,
                            row: {
                              ...row,
                              ack: e.target.value,
                            },
                          },
                        })
                      }
                      displayEmpty
                      className={classes.selectEmpty}
                    >
                      <MenuItem value={"E"}>E</MenuItem>
                      <MenuItem value={"P"}>P</MenuItem>
                      <MenuItem value={"F"}>F</MenuItem>
                      <MenuItem value={"N/A"}>N/A</MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell align="center">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      margin="normal"
                      label="Date picker dialog"
                      format="MM/dd/yyyy"
                      style={{
                        marginTop: 0,
                        transform: "translateY(-5px)",
                      }}
                      value={row["req-date"] || null}
                      onChange={(date) => {
                        dispatch({
                          type: "edit",
                          value: {
                            rowIndex: i,
                            row: {
                              ...row,
                              "req-date": moment(date),
                              "del-date": moment(date),
                            },
                          },
                        });
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </TableCell>
                <TableCell align="center">
                  <span
                    style={{
                      color: row.error ? "red" : "green",
                      fontWeight: "bold",
                    }}
                  >
                    {row["sales-order"] ? row.status : ""}
                  </span>
                </TableCell>
                <TableCell align="center">
                  <IconButton
                    onClick={() =>
                      dispatch({ type: "clear", value: { rowIndex: i } })
                    }
                  >
                    <ClearIcon />
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  {i !== 0 ? (
                    <IconButton
                      onClick={() =>
                        dispatch({ type: "remove", value: { rowIndex: i } })
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  ) : (
                    <div></div>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Button
          variant="contained"
          color="primary"
          style={{ marginLeft: "50%", marginTop: "1%", marginBottom: "1%" }}
          onClick={() => prepareData()}
        >
          Submit
        </Button>
      </TableContainer>
    </>
  );
}
