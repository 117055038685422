import app from "firebase/app";
import "firebase/auth";
import "firebase/firebase-firestore";
import "firebase/functions";
import moment from "moment";
import withTries from "utils/withTries";

var firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

class FirebaseApi {
  constructor() {
    app.initializeApp(firebaseConfig);
    this.auth = app.auth();
    this.db = app.firestore();
  }

  addDocToFirebase = async payload => {
    const collection = this.getRef("logs");
    payload.forEach(async item => {
      await this.getDB().runTransaction(async transaction => {
        const docRef = collection.doc();
        const id = docRef.id;
        const { error } = await withTries(() => {
          return transaction.set(docRef, {
            ...item,
            id,
            date: moment().format("MM-DD-YYYY"),
            dateTime: moment().toDate()
          });
        });
        if (error) {
          console.log("error", error);
        }
      });
    });
  };

  getDocsFromFirebase = async date => {
    const response = await this.getRef("logs")
      .where("date", "==", moment(date).format("MM-DD-YYYY"))
      .get();
    return this.extractDocs(response);
  };

  extractDocs = data => data.docs.map(doc => doc.data());

  getFirestore = () => this.db;

  getCollection = collectionName => this.db.collection(collectionName);

  getDB = () => this.db;

  getRef = collectionName => this.db.collection(collectionName);
}

export default new FirebaseApi();
