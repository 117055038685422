import withAsync from "./withAsync";

const withTries = async (fn, maxTries = 3, tries = 0) => {
  const { response, error } = await withAsync(() => fn());

  if (error && tries < maxTries) {
    return withTries(fn, maxTries, tries + 1);
  } else if (error) {
    return {
      error,
      response: null
    };
  }
  return {
    response,
    error: null
  };
};

export default withTries;
