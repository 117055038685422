import React, { useState } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";

import Firebase from "api/FirebaseApi";

export default function Logs() {
  const [selectedDate, setSelectedDate] = useState(
    moment().format("MM-DD-YYYY")
  );
  const [logData, setLogData] = useState([]);

  const handleDateChange = date => setSelectedDate(moment(date));

  const handleSubmit = async () =>
    setLogData(await Firebase.getDocsFromFirebase(selectedDate));

  return (
    <form>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "5rem",
          marginBottom: "5rem"
        }}
      >
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            margin="normal"
            id="date-picker-dialog"
            label="Date picker dialog"
            format="MM-dd-yyyy"
            value={selectedDate}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              "aria-label": "change date"
            }}
          />
        </MuiPickersUtilsProvider>
        <Button onClick={handleSubmit}>Submit</Button>
      </div>
      <div>
        <TableContainer component={Paper}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Sales Order</TableCell>
                <TableCell align="center">Order Type</TableCell>
                <TableCell align="center">Priority</TableCell>
                <TableCell align="center">Acknowledgement</TableCell>
                <TableCell align="center">Request Date</TableCell>
                <TableCell align="center">Schedule Lock</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Scan In Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {logData
                .sort((a, b) =>
                  moment(a.dateTime.seconds).diff(moment(b.dateTime.seconds))
                )
                .map((data, i) => (
                  <TableRow key={i}>
                    <TableCell align="center">{data["sales-order"]}</TableCell>
                    <TableCell align="center">{data["order-type"]}</TableCell>
                    <TableCell align="center">
                      {data.priority ? <p>HOT</p> : null}
                    </TableCell>
                    <TableCell align="center">{data.ack}</TableCell>
                    <TableCell align="center">{data["req-date"]}</TableCell>
                    <TableCell align="center">{data["sched-lock"]}</TableCell>
                    <TableCell align="center">{data.status}</TableCell>
                    <TableCell align="center">
                      {moment(data.dateTime.toDate()).format(
                        "MM-DD-YYYY hh:mm:ss"
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </form>
  );
}
